import { loyaltyWidgetModalInEditorActionSrc136Evid333 } from '@wix/bi-logger-loyalty-data/v2';
import { EventType } from '@wix/editor-platform-sdk-types';

import { openAddStatusWidgetPanel } from './openAddStatusWidgetPanel';
import { EditorScriptContext } from './types';
import { markStatusWidgetPanelAsShown, wasStatusWidgetPanelShown } from './userPreferences';

export const registerPageNavigationEvent = async (context: EditorScriptContext) => {
  const { editorSDK, flowAPI } = context;

  await editorSDK.addEventListener(EventType.focusedPageChanged, async (event) => {
    const { pageRef } = event.detail;
    const { appDefinitionId } = await editorSDK.pages.getPageData('', { pageRef });

    if (appDefinitionId === flowAPI.environment.appDefinitionId) {
      const wasShown = await wasStatusWidgetPanelShown(editorSDK);
      if (wasShown) {
        return;
      }

      await markStatusWidgetPanelAsShown(context);

      await flowAPI.bi?.report(
        loyaltyWidgetModalInEditorActionSrc136Evid333({
          action: 'modal_opened',
          open_origin: 'first_time_shown',
        }),
      );

      await openAddStatusWidgetPanel({ context, biOpenOrigin: 'first_time_shown' });
    }
  });
};
